.notDisplay {
  display: none;
}

.line-btn {
  display: flex;
  align-items: center;
  position: absolute;
  width: 7%;
  background: #ffffff;
  border: 1px solid #ffffff;
  &:hover {
    border: 1px solid #2c2e32;
    color: #eb5e52;
    background-color: #ffffff;
    cursor: pointer;
  }
  // h1 {
  //   font-size: 1.1rem;
  // }
}

.bar-btn {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0%;
  width: 6%;
  background: #ffffff;
  border: 1px solid #ffffff;
  &:hover {
    border: 1px solid #2c2e32;
    color: #eb5e52;
    background-color: #ffffff;
    cursor: pointer;
  }
  // h1 {
  //   font-size: 1.1rem;
  // }
}

.choro-map {
  display: flex;
  align-items: center;
  position: absolute;
  right: 61%;
  padding: 0%;
  width: 5%;
  background: #ffffff;
  border: 1px solid #ffffff;
  &:hover {
    border: 1px solid #2c2e32;
    color: #eb5e52;
    background-color: #ffffff;
    cursor: pointer;
  }
  // h1 {
  //   font-size: 1.1rem;
  // }
}

.bubble-map {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 0%;
  width: 11%;
  background: #ffffff;
  border: 1px solid #ffffff;
  &:hover {
    border: 1px solid #2c2e32;
    color: #eb5e52;
    background-color: #ffffff;
    cursor: pointer;
  }
  // h1 {
  //   font-size: 1.1rem;
  // }
}
