h3 {
  font-size: 1.5rem;
  padding: 1rem;
}
.filterTitle {
  color: rgba(0, 0, 0, 0.85);
  font-family: Roboto;
  font-weight: 600;
  margin-left: 1%;
}
.italic {
  font-style: italic;
  margin-left: 0.5%;
}
