$greyColor: #4d4f52;
.Graph-Container {
  height: 600px;
  position: relative;
  button {
    color: gray;
    cursor: pointer;
    margin-top: 10px;
    border: none;
    background: none;
    padding: 0;
  }
  button:hover {
    color: #47837f;
  }
}

.display-none {
  display: none;
}

.dwnld-btn {
}

// .reset-btn {
//   text-decoration: none;
//   border: 2px solid grey;
// }
.loader-container {
  box-sizing: border-box;
  height: 600px;
  width: 887px;
  margin-top: 50;
  margin-right: 200;
  margin-bottom: 75;
  margin-left: 80;
}

.loader {
  padding-top: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  .graph-title {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin: 0;
  }
  .TopBar {
    display: flex;
    background-color: #212121;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
    .Sauti-Logo {
      font-family: Montserrat;
      font-size: 3rem;
      font-weight: 1000;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      margin: 20px;
      color: white;
      margin-left: 40px;
      text-decoration: none;
      a {
        text-decoration: none;
        color: white;
      }
      span {
        color: #eb5e52;
      }
    }
    .Navigation {
      display: flex;
      justify-content: space-evenly;
      a {
        text-decoration: none;
        color: white;
        margin-right: 40px;
        font-family: Helvetica;
        font-size: 1.2rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
  }
  .main-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 0px;
    margin-bottom: 100px;
    .main-header {
      display: flex;
      align-items: center;
      .header {
        height: 50px;
        width: 25vw;
        background: #2c2e32;
        // h1 {
        //   font-family: "Montserrat", sans-serif;
        //   font-size: 18px;
        //   font-weight: 600;
        //   color: #ffffff;
        //   margin-left: 20px;
        // }
      } //header
      .data-header {
        height: 50px;
        width: 74.1vw;
        background: #f5f5f5;
        p {
          padding-left: 1.5%;
          font-size: 21px;
          color: #070707;
          font-family: Helvetica, sans-serif;
        }
      }
    }
    .content-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      width: 98%;
      margin: 40px auto;
      .chart-container {
        width: calc(75% - 40px);
        .Chart {
          height: 500px;
          h2 {
            padding-bottom: 35px;
            font-family: Helvetica;
            font-size: 2.8rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyColor;
          }
          .lineCont {
            display: flex;
            padding-bottom: 20px;
            .lineOne {
              margin-left: 40px;
              border-bottom: 1px solid #d0d2d6;
              width: 50%;
            }
            .lineTwo {
              border-bottom: 4px solid #d0d2d6;
              width: 50%;
            }
          }
          .method-title {
            padding: 50px 40px 20px 0px;
            font-family: Helvetica;
            font-size: 2.8rem;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $greyColor;
          }
          p {
            padding: 10px 0px 10px 40px;
            font-family: Helvetica, sans-serif;
            font-size: 1.4rem;
            line-height: 1.2;
            color: $greyColor;
            margin-bottom: 50px;
          }
        }
      }
      .options-loader {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .graph-titles-container {
    display: flex;
    width: 75%;
    height: 4vw;
    position: absolute;
    top: 10%;
    // align-items: center;
    .graph-title-small {
      font-size: 1.1rem;
      padding: 0 0 0 10px;
    }
    .graph-title-diplay {
      display: flex;
      width: 35%;
      margin: 0;
    }
  }
}

.account-page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // h1 {
  //   text-align: left;
  //   font-size: 4.5rem;
  //   margin-bottom: 4%;
  // }
  p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-row-col-admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // h1 {
  //   text-align: left;
  //   font-size: 4.5rem;
  //   margin-bottom: 4%;
  // }
  p {
    font-size: 1.7rem;
    line-height: 1.5;
  }
}

.free-border {
  border-bottom: 1px solid rgb(177, 177, 177);
  width: 93%;
  // padding-top: 1%;
}

.free-perk1 {
  padding-top: 7%;
}

.free-perk2 {
  padding-top: 7%;
}

.free-perk3 {
  padding-top: 7%;
}

.free-perk4 {
  padding-top: 7%;
}

.account-bottom-btn-ctn {
  padding-top: 30%;
  padding-bottom: 26%;
}

.card-shadow1 {
  -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
}

.card-shadow2 {
  -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
}

.card-shadow3 {
  -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
}

.card-shadow4 {
  -webkit-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 0px 16px -5px rgba(0, 0, 0, 0.26);
}

.edit-btn {
  padding-left: 65%;
  padding-bottom: 2%;
}

.container-row-col-top-col {
  // h1 {
  //   font-size: 5rem;
  // }
  p {
    font-size: 2rem;
  }
}

.account-type1 {
  margin-top: 8.7%;
}

.text-highlighted {
  color: #e84a34;

  &__primary {
    color: #006ba1;
  }
}
