@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap");

/* Note that thi root sets the base, which is why it doesn't match with the theme. */
:root {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.graph-title-small {
  font-size: 1.3rem;
  font-family: "Helvetica", sans-serif;
  margin-top: 5px;
}
.hero-img {
  background: url(./dashboard/Images/Hero-img-mediumjpg.jpg) left no-repeat;
}
@media screen and (min-width: 2800px) {
  .hero-img {
    background: url(./dashboard/Images//Hero-img.jpg) left no-repeat;
  }
}
@media screen and (max-width: 760px) {
  .hero-img {
    background: url(./dashboard/Images//Hero-img-small.jpg) left no-repeat;
  }
}

/* need this for the filter options */
ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
/* need this for the filter options */
ul {
  list-style-type: disc;
  /* list-style-position: inside; */
}
ol {
  list-style-type: decimal;
  /* list-style-position: inside; */
}
ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  /* list-style-position: inside; */
  margin-left: 15px;
}
ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
.background-image {
  background: url("./dashboard/Images/Hero-img.jpg");
}
/* list-style-position: inside; */

/* margin-left: 15px;
} */
#reset {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  padding: 5rem 0;
}
.container {
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}
.pricing-table {
  display: flex;
  justify-content: space-evenly;
}
.table-item {
  font-size: 2rem;
  /* text-transform: uppercase; */
  /* text-align: center; */
  padding: 2rem;
  position: relative;
  z-index: 1;
}
.table-item::before,
.table-item::after {
  content: "";
  position: absolute;
  border: 2px solid grey;
}
.table-item::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.table-item::after {
  top: -3px;
  left: -3px;
  width: calc(100% + (3px * 2));
  height: calc(100% + (3px * 2));
  z-index: -2;
}
.table-item i:last-of-type {
  font-size: 10rem;
  margin-bottom: 2rem;
  padding: 1rem;
  color: rgb(0, 140, 255);
}
.subscription-type {
  letter-spacing: 1px;
}
.features-list {
  list-style: none;
  margin: 1.5rem 0;
  letter-spacing: 1px;
}
.features-item {
  border-bottom: 0.5px solid lightgrey;
  font-size: 2rem;
  line-height: 1.5;
  padding: 1.5rem 0;
}

.subscription-price {
  display: block;
  font-size: 3.5rem;
  color: rgb(3, 128, 3, 0.75);
}
.cta {
  display: inline-block;
  text-decoration: none;
  color: black;
  border: 2px solid #eb5e52;
  border-radius: 5px;
  padding: 1rem 3rem;
  margin-top: 1.5rem;
  transition: 0.5s ease;
}
.cta:hover {
  color: white;
  background-color: #eb5e52;
  cursor: pointer;
}
.hide {
  display: none;
}
.extend {
  width: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
