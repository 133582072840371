.dataSeriesBox {
  border-bottom: 1px solid black;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.selectable {
  opacity: 1;
  padding: 1rem 1em;
  font-size: 1.4rem;
  font-family: inherit;
  cursor: pointer;
}
.selectable:hover {
  background: rgba(80, 73, 73, 0.5);
  opacity: 1;
}

.limited {
  opacity: 1;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  font-family: inherit;
  cursor: pointer;
  border-bottom: 1px solid darkgray;
  background: rgba(0, 0, 0, 0.5);
}

#root {
  font-family: "Roboto";
}
