.all-btn {
  display: flex;
  border: 1px solid #ffffff;
  cursor: pointer;
  padding-left: 2rem;
  h1 {
    font-size: 1.1rem;
    margin: auto;
    padding-left: 5%;
  }
}
