.d3 {
  svg {
    display: block;
    width: 100%;
    height: 500px;
  }
  svg .country {
    stroke: #798897;
    stroke-width: 1px;
  }
  svg .country:hover {
    stroke: black;
    stroke-width: 1px;
    cursor: pointer;
  }
  svg .label {
    font-family: "Roboto", sans-serif;
  }
  svg .text1 {
    font-family: "Roboto", sans-serif;
    font-size: 2.3rem;
    //stroke-width: 1px;
    //text-shadow: 0 0 2px #fff;
  }
}

.choro-parent-h2 {
  margin-bottom: 5rem;
  border: 1px solid #2c2e32;
  color: #ffffff;
  background-color: #2c2e32;
  padding: 1%;
}

.noData {
  color: red;
}
